import React from 'react';

import Loader, { IProps as LoaderProps } from './Loader';

import styles from './LoaderBlock.module.scss';

export type IProps = LoaderProps

const LoaderBlock: React.FC<IProps> = props => {
    const { ...loaderProps } = props;

    if (!loaderProps.size) {
        loaderProps.size = 'xlarge';
    }

    return (
        <div className={styles['loader-block']}>
            <Loader {...loaderProps} />
        </div>
    );
};

export default LoaderBlock;