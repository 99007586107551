import { PrepareChar } from '@frontend/jetlend-web-ui/src/ui/inputs/MaskInput/MaskInput';

const SEVEN_CHAR = '7';
const EIGHT_CHAR = '8';

export const getCorrectFirstPhoneNumberFigure: PrepareChar = (char, masked) => {
    if (char === EIGHT_CHAR && masked.value === '') {
        return SEVEN_CHAR;
    }

    return char;
};