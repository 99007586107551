import {
    useState,
    useCallback,
} from 'react';

/**
 * Хук контроля ввода значений в input для улучшения UX
 * @category Hooks
 * @param value - значение поля
 * @param inputRef - ref контролируемого input
 * @param onChange - хендлер изменения input
 *
 * @returns Возвращает функцию-хендлер с дополнительными проверками вводимых данных
 */
export default function useInputControl (value: any, inputRef: React.MutableRefObject<HTMLInputElement>, onChange: (eventOrValue: any) => void): (event: any) => void {

    const [ _, setInputValue ] = useState(value);

    const didInputChanged = useCallback((event) => {
        onChange && onChange(event);

        const input = inputRef?.current;

        // Предотвращаем ошибку установки выделения, т.к. не все типы поддерживают методы setSelectionRange
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/setSelectionRange#invalidstateerror
        if (![
            'text',
            'search',
            'password',
            'tel',
            'url',
        ].includes(input.type?.toLowerCase())) {
            return;
        }

        // Исправляем баг со смещением курсора при вводе в середине
        let cursorPosition = input.selectionStart;
        setTimeout(() => {
            setInputValue((prev) => {
                // Разница в длине строк предыдущего значения и текущего
                const lengthDifference = Math.abs(event.target.value.toString()?.length - prev?.length);
                // Длина текущего ввода
                const currentTypedValueLength = event.nativeEvent?.data?.length;

                // Если при удалении количество символов уменьшилось более чем на 1
                // т.е., удалился символ + сменилось форматирование (при изменении разряда убрался пробел)
                if (lengthDifference > 1 && event.nativeEvent?.inputType === 'deleteContentBackward') {
                    // Проверка, на случай, если удаляемый элемент был первым , тогда устанавливаем курсор в начало строки
                    // Иначе уменьшаем позицию курсора на 1
                    cursorPosition = Math.max(0, cursorPosition - 1);
                }

                // Если при добавлении символов кол-во символов в поле увеличилось более чем на кол-во введённых
                // т.е., добавились символы + сменилось форматирование (при изменении разряда добавился пробел)
                if (lengthDifference > currentTypedValueLength && event.nativeEvent?.inputType === 'insertText') {
                    // Увеличиваем значение позиции курсора на кол-во введённых символов
                    cursorPosition += currentTypedValueLength;
                }

                return event.target.value;
            });

            input.setSelectionRange(cursorPosition, cursorPosition);
        });
    }, [inputRef, onChange]);

    return didInputChanged;
};