'use client';

import type {
    FactoryOpts,
    Masked,
} from 'imask';
import React, {
    useEffect,
    useState,
} from 'react';
import { useIMask } from 'react-imask';
import { connectToField } from '@frontend/jetlend-core/src/ui/inputs/connect';
import {
    InlineTextInputComponent,
    IProps as InlineTextInputProps,
} from '../InlineTextInput/InlineTextInput';

interface IProps extends InlineTextInputProps {
    /**
    * Mask docs: https://imask.js.org/
    */
    mask: string | RegExp;
    prepareChar?: PrepareChar;
}

export type PrepareChar = (char: string, masked: Masked) => string

const MaskInput = (props: IProps) => {
    const {
        mask,
        onChange,
        value,
        prepareChar,
        ...inputProps
    } = props;

    const [ options ] = useState<FactoryOpts>({
        mask: mask as unknown as Masked,
        prepareChar,
    });
    const {
        ref,
        maskRef,
    } = useIMask(options, {
        onAccept: newValue => onChange && onChange(newValue),
    });

    const [firstUpdate, setFirstUpdate] = useState(false);

    useEffect(() => {
        if (firstUpdate) {
            return;
        }
        maskRef.current?.updateValue();
        setFirstUpdate(true);
    }, [value]);

    return (
        <InlineTextInputComponent
            // imask returns not compatible typing for HTMLInputElement
            inputRef={ref as never}
            value={value}
            {...inputProps}
        />
    );
};


export const MaskInputField = connectToField(MaskInput);

export default MaskInput;
