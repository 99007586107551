import { useEffect } from 'react';

export type WindowResizeEffectHandler = () => void;

/**
 * TODO
 * @category Hooks
 */
export function useWindowResizeEffect(callback: WindowResizeEffectHandler, dependencyList?: any[]) {
    useEffect(() => {
        let disposed = false;

        const delayedHandler = () => {
            callback();
            setTimeout(() => !disposed && callback(), 250);
        };

        window.addEventListener('resize', delayedHandler);

        callback();

        return () => {
            disposed = true;
            window.removeEventListener('resize', delayedHandler);
        };
    }, dependencyList);
}
