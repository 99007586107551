/**
 * TODO
 * @category Utils
 */
export function buildRange(start: number, end: number, step = 1): number[] {
    if (Math.abs(step) < 0.00001) {
        return [];
    }

    if (start < end) {
        if (step > 0) {

            const result = [];

            for (let n = start; n <= end; n += step) {
                result.push(n);
            }

            return result;
        }

        return [];
    }

    if (start > end) {
        if (step < 0) {
            const result = [];

            for (let n = start; n >= end; n += step) {
                result.push(n);
            }

            return result;
        }

        return [];
    }

    return [];
}

/**
 * TODO
 * @category Utils
 */
export function arraySum<TItem>(items: TItem[], selector: (item: TItem) => number): number {
    if (!items) {
        return 0;
    }

    return items.reduce((acc, item) => acc + selector(item), 0);
}

/**
 * TODO
 * @category Utils
 */
export function arrayAvg<TItem>(items: TItem[], selector: (item: TItem) => number): number {
    if (!items) {
        return 0;
    }

    if (items.length === 0) {
        return 0;
    }

    return items.reduce((acc, item) => acc + selector(item) || 0.0, 0) / items.length;
}

/**
 * Приводит массив к списочному виду по указанному ключу.
 *
 * @param list список для итераций
 * @param keySelector функция для выбора ключа
 * @returns объект, где элементы массива проиндексированны по ключу
 *
 * @category Utils
 *
 * @example
 * const [ underwriters ] = useSimpleApiHandler(underwritersListHandler);
 * const underwritersById = arrayToMap(underwriters, underwriter => underwriter.id);
 *
 * const loanRequestUnderwriter = underwritersById[loanRequest.underwriterId];
 */
export function arrayToMap<T, K extends string|number|symbol>(list: T[], keySelector: ((item: T) => K)): Record<K, T> {
    if (typeof keySelector !== 'function') {
        throw new Error(`keySelector must be a function, given ${typeof keySelector} (${keySelector})`);
    }

    return (list ?? []).reduce<Record<K, T>>((acc, item) => {
        const key = keySelector(item);
        acc[key] = item;

        return acc;
    }, {} as any);
}
