import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ActionCreator = (...args) => any;

/**
 * Хук для оборачивания actionCreator'ов методом dispatch.
 *
 * @param actions Одно или несколько действий в массиве, которые нужно обернуть с помощью dispatch
 * @returns Возвращает обернутое действие или массив действий, в зависимости от переданного параметра
 * @category Hooks
 *
 * @see {@link simpleSagaHandlerV2}
 *
 * @example
 * // Можно обернуть 1 действие
 * const updateTableState = useActions(updateTableStateSagaHandler.action);
 *
 * // Можно обернуть сразу несколько действий и на выходе получить массив
 * const [
 *      updateColumnState,
 *      updateRowState
 * ] = useActions([
 *      updateColumnStateSagaHandler.action,
 *      updateRowStateSagaHandler.action,
 * ]);
 */
export function useActions<TParams extends ActionCreator|ActionCreator[]>(
    actions: TParams
): TParams {
    const dispatch = useDispatch();

    return useMemo<TParams>(
        () => {
            if (Array.isArray(actions)) {
                return actions.map(action => bindActionCreators(action, dispatch)) as TParams;
            }

            return bindActionCreators(actions, dispatch) as TParams;
        },
        [dispatch]
    );
}