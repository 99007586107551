import React, {
    useState,
    useEffect,
} from 'react';

export const MOBILE_SMALL_WIDTH = 374;
export const MOBILE_WIDTH = 568;
export const MOBILE_LANDSCAPE_HEIGHT = 459;
export const TABLET_PORTRAIT_WIDTH = 768;
export const TABLET_LANDSCAPE_WIDTH = 1024;
export const DESKTOP_EXTRA_WIDTH = 1292;
export const LAPTOP_WIDTH = 1366;
export const DESKTOP_MEDIUM_WIDTH = 1440;

const IS_CLIENT = typeof window === 'object';

/**
 * Determines whether the current viewport width, or the specified width, represents a screen size smaller than or equal to the small mobile size (<=374px).
 * @param {number} [width] - The optional width of the viewport.
 * @returns {boolean} - True if the screen size is not considered small mobile; otherwise, false.
 * @category Utils
 */
export function isSmallMobile(width?: number): boolean {
    if (!IS_CLIENT) {
        return false;
    }

    if (width === undefined) {
        width = window.innerWidth;
    }

    return width <= MOBILE_SMALL_WIDTH;
}

/**
 * Determines whether the current viewport width, or the specified width, represents a screen size smaller than or equal to the mobile size (<=568px).
 * @param {number} [width] - The optional width of the viewport.
 * @returns {boolean} - True if the screen size is not considered mobile; otherwise, false.
 * @category Utils
 */
export function isMobile(width?: number): boolean {
    if (!IS_CLIENT) {
        return false;
    }

    if (width === undefined) {
        width = window.innerWidth;
    }

    return width <= MOBILE_WIDTH;
}

/**
 * Determines whether the current viewport width, or the specified width, represents a screen size larger than the mobile size (>568px).
 * @param {number} [width] - The optional width of the viewport.
 * @returns {boolean} - True if the screen size is not considered not mobile; otherwise, false.
 * @category Utils
 */
export function isNotMobile(width?: number): boolean {
    if (!IS_CLIENT) {
        return false;
    }

    if (width === undefined) {
        width = window.innerWidth;
    }

    return width > MOBILE_WIDTH;
}

/**
 * Determines whether the current viewport width, or the specified width, represents a screen size smaller than or equal to the tablet portrait size (<=768px).
 * @param {number} [width] - The optional width of the viewport.
 * @returns {boolean} - True if the screen size is not considered portrait tablet; otherwise, false.
 * @category Utils
 */
export function isTabletPortrait(width?: number): boolean {
    if (!IS_CLIENT) {
        return false;
    }

    if (width === undefined) {
        width = window.innerWidth;
    }

    return width <= TABLET_PORTRAIT_WIDTH;
}

/**
 * Determines whether the current viewport width, or the specified width, represents a tablet screen size (568..1024px).
 * @param {number} [width] - The optional width of the viewport.
 * @returns {boolean} - True if the screen size is considered a tablet; otherwise, false.
 * @category Utils
 */
export function isTablet(width?: number): boolean {
    if (!IS_CLIENT) {
        return false;
    }

    if (width === undefined) {
        width = window.innerWidth;
    }

    return isNotMobile(width) && width <= TABLET_LANDSCAPE_WIDTH;
}

/**
 * Determines whether the current viewport width, or the specified width, represents a screen size larger than the tablet landscape size (>1024px).
 * @param {number} [width] - The optional width of the viewport.
 * @returns {boolean} - True if the screen size is considered desktop; otherwise, false.
 * @category Utils
 */
export function isDesktop(width?: number): boolean {
    if (!IS_CLIENT) {
        return false;
    }

    if (width === undefined) {
        width = window.innerWidth;
    }

    return width > TABLET_LANDSCAPE_WIDTH;
}

/**
 * Determines whether the current viewport width, or the specified width, represents a screen size smaller than or equal to the tablet landscape size (<=1024px).
 * @param {number} [width] - The optional width of the viewport.
 * @returns {boolean} - True if the screen size is not considered desktop; otherwise, false.
 * @category Utils
 */
export function isNotDesktop(width?: number): boolean {
    if (!IS_CLIENT) {
        return false;
    }

    if (width === undefined) {
        width = window.innerWidth;
    }

    return width <= TABLET_LANDSCAPE_WIDTH;
}

/**
 * Determines whether the current viewport width, or the specified width, represents a screen size larger than the basic desktop size (1292px).
 * @param {number} [width] - The optional width of the viewport.
 * @returns {boolean} - True if the screen size is considered basic desktop; otherwise, false.
 * @category Utils
 */
export function isExtraDesktop(width?: number): boolean {
    if (!IS_CLIENT) {
        return false;
    }

    if (width === undefined) {
        width = window.innerWidth;
    }

    return width > DESKTOP_EXTRA_WIDTH;
}

/**
 * Determines whether the current viewport width, or the specified width, represents a screen size smaller than or equal to the basic desktop size (1292px).
 * @param {number} [width] - The optional width of the viewport.
 * @returns {boolean} - True if the screen size is not considered basic desktop; otherwise, false.
 * @category Utils
 */
export function isNotExtraDesktop(width?: number): boolean {
    if (!IS_CLIENT) {
        return false;
    }

    if (width === undefined) {
        width = window.innerWidth;
    }

    return width <= DESKTOP_EXTRA_WIDTH;
}

/**
 * Determines whether the current viewport width, or the specified width, represents a screen size larger than the basic laptop size (1366px).
 * @param {number} [width] - The optional width of the viewport.
 * @returns {boolean} - True if the screen size is considered basic desktop; otherwise, false.
 * @category Utils
 */
export function isLaptop(width?: number): boolean {
    if (!IS_CLIENT) {
        return false;
    }

    if (width === undefined) {
        width = window.innerWidth;
    }

    return width > LAPTOP_WIDTH;
}

/**
 * Determines whether the current viewport width, or the specified width, represents a screen size smaller than or equal to the basic laptop size (1366px).
 * @param {number} [width] - The optional width of the viewport.
 * @returns {boolean} - True if the screen size is not considered basic desktop; otherwise, false.
 * @category Utils
 */
export function isNotLaptop(width?: number): boolean {
    if (!IS_CLIENT) {
        return false;
    }

    if (width === undefined) {
        width = window.innerWidth;
    }

    return width <= LAPTOP_WIDTH;
}

/**
 * Determines whether the current viewport width, or the specified width, represents a screen size larger than the medium desktop size (1440px).
 * @param {number} [width] - The optional width of the viewport.
 * @returns {boolean} - True if the screen size is considered large desktop; otherwise, false.
 * @category Utils
 */
export function isLargeDesktop(width?: number): boolean {
    if (!IS_CLIENT) {
        return false;
    }

    if (width === undefined) {
        width = window.innerWidth;
    }

    return width > DESKTOP_MEDIUM_WIDTH;
}

/**
 * Determines whether the current viewport width, or the specified width, represents a screen size smaller than or equal to the medium desktop size (1440px).
 * @param {number} [width] - The optional width of the viewport.
 * @returns {boolean} - True if the screen size is not considered large desktop; otherwise, false.
 * @category Utils
 */
export function isNotLargeDesktop(width?: number): boolean {
    if (!IS_CLIENT) {
        return false;
    }

    if (width === undefined) {
        width = window.innerWidth;
    }

    return width <= DESKTOP_MEDIUM_WIDTH;
}

/**
 * React Hook that returns an object representing the current window size.
 * This Hook updates the window size in response to resize events.
 * @returns {Object} - An object containing the current window size with "width" and "height" properties.
 */
export function useWindowSize() {
    function getSize() {
        return {
            width: IS_CLIENT ? window.innerWidth : undefined,
            height: IS_CLIENT ? window.innerHeight : undefined,
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect((): (void | (() => void | undefined)) => {
        if (!IS_CLIENT) {
            return;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    // eslint-disable-next-line
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}

export interface ResponsiveProps extends React.PropsWithChildren {
    smallMobile?: JSX.Element|React.ReactNode;
    mobile?: JSX.Element|React.ReactNode;
    notMobile?: JSX.Element|React.ReactNode;
    tabletAndMobile?: JSX.Element|React.ReactNode;
    tablet?: JSX.Element|React.ReactNode;
    tabletPortrait?: JSX.Element|React.ReactNode;
    desktop?: JSX.Element|React.ReactNode;
    notDesktop?: JSX.Element|React.ReactNode;
    extraDesktop?: JSX.Element|React.ReactNode;
    notExtraDesktop?: JSX.Element|React.ReactNode;
    custom?: {
        [width: number]: JSX.Element|React.ReactNode;
    };
    invertedCustomPriority?: boolean;
}

const Responsive: React.FC<ResponsiveProps> = ({
    smallMobile,
    mobile,
    notMobile,
    tabletAndMobile,
    tablet,
    tabletPortrait,
    desktop,
    notDesktop,
    extraDesktop,
    notExtraDesktop,
    custom,
    children,
    invertedCustomPriority,
}): any => {
    const size = useWindowSize();

    if (smallMobile !== undefined && isSmallMobile(size.width)) {
        return smallMobile || null;
    }

    if (mobile !== undefined && isMobile(size.width)) {
        return mobile || null;
    }

    if (notMobile !== undefined && isNotMobile(size.width)) {
        return notMobile || null;
    }

    if (tabletPortrait !== undefined && isTabletPortrait(size.width)) {
        return tabletPortrait || null;
    }

    if (tablet !== undefined && isTablet(size.width)) {
        return tablet || null;
    }

    if (tabletAndMobile !== undefined && (isTablet(size.width) || isMobile(size.width))) {
        return tabletAndMobile;
    }

    if (notDesktop !== undefined && isNotDesktop(size.width)) {
        return notDesktop || null;
    }

    if (desktop !== undefined && isDesktop(size.width)) {
        return desktop || null;
    }

    if (notExtraDesktop !== undefined && isNotExtraDesktop(size.width)) {
        return notExtraDesktop || null;
    }

    if (extraDesktop !== undefined && isExtraDesktop(size.width)) {
        return extraDesktop || null;
    }

    if (custom && size.width) {
        let responsiveContent: React.ReactNode;
        const keys = Object.keys(custom)
            .map(key => Number.parseInt(key))
            .filter(w => w > 0)
            .sort((a, b) => invertedCustomPriority ? b - a : a - b);

        for (let idx = 0; idx < keys.length; idx++) {
            const maxWidth = keys[idx];
            if (size.width < maxWidth) {
                responsiveContent = custom[maxWidth];
            }
        }

        if (responsiveContent) {
            return responsiveContent;
        }
    }

    return children || null;
};

export default Responsive;
