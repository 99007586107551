import React from 'react';
import error from '@frontend/jetlend-assets/icons/icon--error-alert.svg';
import warning from '@frontend/jetlend-assets/icons/icon--warning-alert.svg';
import success from '@frontend/jetlend-assets/icons/icon--success-alert.svg';
import info from '@frontend/jetlend-assets/icons/icon--info-alert.svg';
import waiting from '@frontend/jetlend-assets/icons/icon-waiting.svg';
import { getAssetSrc } from '@ui/utils/getAssetSrc';
import { MessageType } from '../../Message';
import {
    buildClassNames,
    mergeClassNames,
} from '../../../utils/classNameUtils';

import styles from './formAlert.module.scss';

export interface IProps {
    alert: JSX.Element|React.ReactNode;
    type?: MessageType;
    className?: string;
    textClassName?: string;
    background?: boolean;
    imageAlign?: 'center' | 'start' | 'end';
}

const getAlertIcon = (type:MessageType) => {
    switch (type) {
    case 'error':
        return error;

    case 'warning':
        return warning;

    case 'success':
        return success;

    case 'waiting':
        return waiting;

    default:
        return info;
    }
};

export function FormAlert(props: IProps) {
    const {
        alert,
        type = 'error',
        className = '',
        textClassName: externalTextClassName,
        imageAlign = 'center',
    } = props;

    if (!alert) {
        return null;
    }

    const imageClassName = buildClassNames(styles, [
        'image',
        `image--${imageAlign}`,
    ]);

    const containerClassName = mergeClassNames([
        buildClassNames(styles, [
            'container',
            `container--type-${type}`,
            props.background && `container--background-${type}`,
        ]),
        className,
    ]);

    const textClassName = mergeClassNames([
        styles['text'],
        externalTextClassName,
    ]);

    return (
        <div className={containerClassName}>
            <img src={getAssetSrc(getAlertIcon(type))} alt="" className={imageClassName} />
            <div className={textClassName}>{alert}</div>
        </div>
    );
}