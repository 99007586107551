export interface IBaseApiHookOptions {
    /**
     * Флаг, определяющий, когда требуется сделать запрос на загрузку данных.
     * Полезно при запросе данных для модальных или других всплывающих окон.
     *
     * @example
     * const [isOpen] = useModalHandler(exchangeModalHandler);
     * const [exchangeSummary] = useCollectionApiHandler(exchangeSummaryHandler, sourceId, {
     *     fetchWhen: isOpen,
     * });
     */
    fetchWhen?: boolean;
}

/**
 * @internal
 */
export function isFetchRequiredByOptions(options?: IBaseApiHookOptions): boolean {
    const fetchWhen = options?.fetchWhen;
    if (typeof fetchWhen === 'boolean') {
        return fetchWhen;
    }

    return true;
}
